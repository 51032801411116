import React from "react";
import {Link} from "gatsby";

import Body from "components/layout/Body/Body";
import SEO from "components/seo";

const Business = () => (
	<>
		<SEO title="Business - Kevin Mamaqi Kapllani" />
		<h1>Business</h1>
		<p>
			Besides my work I run a few businesses that I have been building in the last 10 years.
			They fuel my knowledge and creativity which I apply back to my job. These are:
		</p>
		<ul>
			<li>Euroviajar.com</li>
			<li>KM devs</li>
			<li></li>
		</ul>
		<p>
			<strong>Full stack engineer</strong> with 9 years of experience working mostly with
			Javascript, (React, React Native and Node). I’m currently focused on React/React Native
			and Node.js, building medical applications. My favourite tools are GIT and VSCode while
			my preferred methodology is continuous integration. More about me… Projects You will
			find a multi-disciplinary selection of relevant professional and academic projects for
			which.
		</p>
		<p>
			Graduado en MSc. Integrated Product Design en TU Delft (Holanda). Especializado en el
			desarrollo de productos para la industria médica. 9 años de experiencia como
			desarrollador. +100 proyectos, +30 de medio/gran tamaño. - Desarrollo React/React Native
			& Svelte. - Desarrollo Node.js - Desarrollo con Python para Signal Processing & Machine
			Learning.
		</p>
		<Link to="/">Go back to the homepage</Link>
	</>
);

Business.Layout = Body;
export default Business;
